/* eslint-disable import/prefer-default-export */
export const assetUrl = 'https://static.coconutcalendar.com/';
export const coconutPrimaryColour = '#2979FF'; // Material-UI's blue A[400]
export const drawerOpenWidth = 240;
export const drawerClosedWidth = 70;
export const topBarHeight = 64;
export const longNameLength = 15;
export const veryLongNameLength = 50;
export const snackbarDuration = 4000;
export const random = 'random';
export const attendeeSearchMinLength = 2; // Minimum inclusive length to search for clients or staff

export const LOCALES = {
  en: 'en',
  fr: 'fr',
  es: 'es',
  ko: 'ko',
  pl: 'pl',
  pt: 'pt',
  ru: 'ru',
  zh: 'zh',
};
export const randomForLanguage = {
  en: `${random}-en`,
  es: `${random}-es`,
  fr: `${random}-fr`,
  ko: `${random}-ko`,
  pl: `${random}-pl`,
  pt: `${random}-pt`,
  ru: `${random}-ru`,
  zh: `${random}-zh`,
};
export const isRandomForLang = {
  [randomForLanguage.en]: 1,
  [randomForLanguage.es]: 1,
  [randomForLanguage.fr]: 1,
  [randomForLanguage.ko]: 1,
  [randomForLanguage.pl]: 1,
  [randomForLanguage.pt]: 1,
  [randomForLanguage.ru]: 1,
  [randomForLanguage.zh]: 1,
};
export const abbreviatedFormatsWithDays = {
  en: 'd[d] h[h] m[m]',
  es: 'd[d] h[h] m[m]',
  fr: 'd [j] h [h] m[m]',
  ko: 'd[일] h[시간] m[분]',
  pl: 'd[d] h[g] m[m]',
  'pt-br': 'd[d] h[h] m[m]',
  ru: 'd[д] h[ч] m[м]',
  'zh-cn': 'd[天] h[小时] m[分钟]',
};
export const ACTIVITY_TYPES = {
  APPOINTMENTS: 'appointments',
  QUEUE_APPOINTMENTS: 'queue-appointments',
};
export const appointmentStatuses = {
  CONFIRMED: 1,
  STATUS_COMPLETE: 2,
  NO_SHOW: 3,
  CANCELLED: 4,
  IN_PROGRESS: 5,
  SERVED: 6,
};
export const STATUS_NAMES = {
  [appointmentStatuses.CONFIRMED]: 'confirmed',
  [appointmentStatuses.STATUS_COMPLETE]: 'completed',
  [appointmentStatuses.NO_SHOW]: 'no_show',
  [appointmentStatuses.CANCELLED]: 'cancelled',
  [appointmentStatuses.IN_PROGRESS]: 'in_progress',
  [appointmentStatuses.SERVED]: 'served',
};
export const NOTIFIABLE_STATUSES = {
  [appointmentStatuses.CONFIRMED]: true,
  [appointmentStatuses.STATUS_COMPLETE]: true,
  [appointmentStatuses.NO_SHOW]: true,
  [appointmentStatuses.CANCELLED]: true,
  [appointmentStatuses.IN_PROGRESS]: false,
  [appointmentStatuses.SERVED]: false,
};
export const absenceStatuses = {
  CONFIRMED: 1,
  COMPLETE: 2,
  NOSHOW: 3,
  CANCELLED: 4,
};
export const ABSENCE_TYPES = {
  PERSONAL: 1,
  SICK: 3,
  VACATION: 4,
  EXTERNAL: 5,
};
export const unassigned = 'unassigned';
export const headerMessageTypes = {
  info: 'info',
  warning: 'warning',
  noMessage: null,
};
export const attendeeStatuses = {
  CONFIRMED: 1,
  ARRIVED: 2,
  NO_SHOW: 3,
  CANCELLED: 4,
};
export const roles = {
  ALL: 0,
  CALL_CENTRE_STAFF: 5,
  KIOSK: 7,
  STAFF: 10,
  STAFF_PLUS: 13,
  STAFF_ADVANCED: 15,
  MANAGER: 20,
  ADMIN: 30,
  COCONUT_ADMIN: 50,
};

export const SECURE_ROLES = [
  roles.CALL_CENTRE_STAFF,
  roles.STAFF,
  roles.STAFF_PLUS,
  roles.STAFF_ADVANCED,
  roles.MANAGER,
  roles.ADMIN,
];

export const ROLE_LABELS = {
  [roles.CALL_CENTRE_STAFF]: 'Contact Centre Staff',
  [roles.KIOSK]: 'Kiosk',
  [roles.STAFF]: 'Staff',
  [roles.STAFF_PLUS]: 'Staff Plus',
  [roles.STAFF_ADVANCED]: 'Staff Advanced',
  [roles.MANAGER]: 'Manager',
  [roles.ADMIN]: 'Admin',
  [roles.COCONUT_ADMIN]: 'Coconut Admin',
};

export const ROLE_LABELS_FORMAT = {
  [roles.ADMIN]: 'Role.Labels.admin',
  [roles.CALL_CENTRE_STAFF]: 'Role.Labels.call_centre_staff',
  [roles.COCONUT_ADMIN]: 'Role.Labels.coconut_admin',
  [roles.KIOSK]: 'Role.Labels.kiosk',
  [roles.MANAGER]: 'Role.Labels.manager',
  [roles.STAFF]: 'Role.Labels.staff',
  [roles.STAFF_ADVANCED]: 'Role.Labels.staff_advanced',
  [roles.STAFF_PLUS]: 'Role.Labels.staff_plus',
};

export const ACTION_TYPE = {
  Add: 'add',
  Edit: 'edit',
  Delete: 'delete',
};

export const questionTypes = {
  TYPE_TEXT: 1,
  TYPE_SELECT: 2,
  TYPE_CHECKBOX: 3,
  TYPE_RADIO: 4,
  TYPE_TEXTAREA: 5,
  TYPE_UPLOAD: 6,
};
export const attendeeTabs = {
  DETAILS: 0,
  QUESTIONS: 1,
};
export const bookedThrough = {
  UNKNOWN: 0,
  QUEUE: 1,
  CLIENT_VIEW: 2,
  SCHEDULE: 3,
  API: 4,
  WAIT_LIST: 5,
  CALL_CENTRE: 6,
  CONSOLE: 7,
  MODERN_CLIENT_VIEW: 9,
  RESERVE_WITH_GOOGLE: 10,
  LOBBY: 12,
  ONLINE_QUEUE: 13,
  DYNAMICS: 16,
  DIGITAL_BANKING_PLATFORM: 17,
  SALESFORCE: 18,
};
export const BOOKED_THROUGH_NAMES = {
  [bookedThrough.UNKNOWN]: 'BookedThrough.unknown',
  [bookedThrough.QUEUE]: 'BookedThrough.device',
  [bookedThrough.CLIENT_VIEW]: 'BookedThrough.client_view',
  [bookedThrough.SCHEDULE]: 'BookedThrough.schedule',
  [bookedThrough.API]: 'BookedThrough.wait_list',
  [bookedThrough.WAIT_LIST]: 'BookedThrough.api',
  [bookedThrough.CALL_CENTRE]: 'BookedThrough.call_centre',
  [bookedThrough.CONSOLE]: 'BookedThrough.console',
  [bookedThrough.MODERN_CLIENT_VIEW]: 'BookedThrough.modern_client_view',
  [bookedThrough.RESERVE_WITH_GOOGLE]: 'BookedThrough.reserve_with_google',
  [bookedThrough.LOBBY]: 'BookedThrough.lobby',
  [bookedThrough.ONLINE_QUEUE]: 'BookedThrough.online_queue',
  [bookedThrough.DYNAMICS]: 'BookedThrough.dynamics',
  [bookedThrough.DIGITAL_BANKING_PLATFORM]:
    'BookedThrough.digital_banking_platform',
  [bookedThrough.SALESFORCE]: 'BookedThrough.salesforce',
};
export const sections = {
  SERVICE: 1,
  LOCATION: 2,
  USER: 3,
  DATE: 4,
  ATTENDEES: 5,
  ATTENDEE_DETAILS: 6,
  MEETING_METHOD: 7,
};
export const paths = {
  [sections.SERVICE]: '/select-service',
  [sections.LOCATION]: '/select-location',
  [sections.USER]: '/select-staff',
  [sections.DATE]: '/select-timeslot',
  [sections.ATTENDEES]: '/select-attendees',
  [sections.ATTENDEE_DETAILS]: '/attendee-details',
};
export const adjustmentTypes = {
  USER: 1,
  LOCATION: 2,
  HOLIDAY: 3,
  OVERRIDE: 4,
};
export const adjustmentsTypeLabels = {
  [adjustmentTypes.USER]: 'Adjustments.type_staff',
  [adjustmentTypes.LOCATION]: 'Adjustments.type_location',
  [adjustmentTypes.HOLIDAY]: 'Adjustments.type_holiday',
  [adjustmentTypes.OVERRIDE]: 'Adjustments.type_location',
};
export const dataExportStatuses = {
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  FAILED: 'failed',
};
export const reportEngagementTypes = {
  ALL: 'all',
  APPOINTMENTS: 'appointments',
  WALKINS: 'walkins',
};
export const reportCategories = {
  ALL: 'all',
  UNCATEGORIZED: 'uncategorized',
};
export const reportResourceSelectors = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  SPECIFIC: 'SPECIFIC',
};
export const reportEvents = {
  DATA_EXPORT_STATUS_UPDATE:
    'App\\Domains\\Reporting\\Events\\DataExportStatusUpdated',
};
export const customerTraffic = {
  ALL: 'all',
  ALL_LOCATIONS: 'All Locations',
  CATEGORY: 'category',
  LOCATION: 'location',
  CATEGORIZED_LOCATION: 'categorizedLocation',
};
export const hoursPages = {
  BUSINESS_HOURS: 0,
  WORKING_HOURS: 1,
  ADJUSTMENTS: 2,
};
export const NEW_HOURS_PAGES = {
  OVERVIEW: 0,
  LOCATION_DEFAULTS: 1,
  STAFF_DEFAULTS: 2,
};
export const exchangeSettingsPages = {
  ISSUES: 0,
  STATUS: 1,
  ACCOUNTS: 2,
  CONFIGURATION: 3,
  ACCOUNT_ASSOCIATIONS: 4,
  TOOLS: 5,
};
export const calendarIntegrations = {
  EXCHANGE: 0,
  GSUITE: 1,
  CALENDAR_SYNC: 2,
};
export const calendarIntegrationLabels = {
  [calendarIntegrations.EXCHANGE]: 'exchange',
  [calendarIntegrations.GSUITE]: 'gsuite',
  [calendarIntegrations.CALENDAR_SYNC]: 'two-way sync',
};
export const exchangeResyncProgress = {
  COMPLETED: 'resyncCompleted',
  LAST_UPDATE: 'resyncLastUpdate',
  TOTAL: 'resyncTotal',
};
export const userAccountDialogTypes = {
  LOG: 0,
  DETAILS: 1,
  RESET: 2,
  RESET_ALL: 3,
};
export const defaultShortcutsTabs = {
  PERSONAL: 0,
  SERVICES: 1,
  LOCATIONS: 2,
  MEETING_METHODS: 3,
};
export const addHoursOptions = {
  HOLIDAY: 'holiday',
  ADJUSTMENT: 'adjustment',
};
export const ADJUST = {
  BUSINESS: 'business',
  WORKING: 'working',
};
export const VARIETY_FROM_TYPE = {
  [adjustmentTypes.USER]: ADJUST.WORKING,
  [adjustmentTypes.LOCATION]: ADJUST.BUSINESS,
  [adjustmentTypes.HOLIDAY]: ADJUST.BUSINESS,
  [adjustmentTypes.OVERRIDE]: ADJUST.BUSINESS,
};
export const frequencies = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
};
export const frequencyLabels = {
  DAILY: 'Hours.day',
  WEEKLY: 'Hours.week',
  MONTHLY: 'Hours.month',
  YEARLY: 'Hours.year',
};
export const holidayFrequencies = {
  NEVER: 'NEVER',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
};
export const holidayFrequencyLabels = {
  NEVER: 'Holidays.never',
  MONTHLY: 'Holidays.monthly',
  YEARLY: 'Holidays.yearly',
};
export const ends = {
  COUNT: 'count',
  NEVER: 'never',
  UNTIL: 'until',
};
export const adjustmentDates = {
  DATE: 'date',
  UNTIL: 'until',
};
export const progressDirection = {
  CONNECT: 'connecting',
  DISCONNECT: 'disconnecting',
};
export const defaultTimezone = 'UTC';
export const midnight = '00:00:00';
export const midnightNextDay = '24:00:00';
export const defaultResource = { id: '0' };
export const adjustmentDateFormat = 'll';
export const exchangeVersion = '2010';
export const firstColourInPicker = '#b80000';
export const ubuntu = 'Ubuntu, sans-serif';
export const arial = 'Arial, sans-serif';
export const helvetica = 'Helvetica, sans-serif';
export const tahoma = 'Tahoma, sans-serif';
export const trebuchetMs = 'Trebuchet MS, sans-serif';
export const verdana = 'Verdana, sans-serif';
export const georgia = 'Georgia, serif';
export const timesNewRoman = 'Times New Roman, serif';
export const fonts = [
  { value: ubuntu, text: ubuntu },
  { value: arial, text: arial },
  { value: helvetica, text: helvetica },
  { value: tahoma, text: tahoma },
  { value: trebuchetMs, text: trebuchetMs },
  { value: verdana, text: verdana },
  { value: georgia, text: georgia },
  { value: timesNewRoman, text: timesNewRoman },
];
export const emailTypes = [
  { value: 1, text: 'Appointment Confirmed - Staff' },
  { value: 2, text: 'Appointment Updated - Staff' },
  { value: 3, text: 'Appointment Cancelled by Client - Staff' },
  { value: 4, text: 'Appointment Cancelled by Client - Client' },
  { value: 5, text: 'Appointment Completed - Client' },
  { value: 6, text: 'Appointment Confirmed - Client' },
  { value: 7, text: 'Appointment Missed - Client' },
  { value: 8, text: 'Appointment Reminder - Client' },
  { value: 9, text: 'Appointment Updated - Client' },
  { value: 11, text: 'Waitlist Confirmed - Client' },
  { value: 12, text: 'Waitlist Confirmed - Staff' },
  { value: 14, text: 'Group Appointment Cancelled - Staff' },
  { value: 15, text: 'Group Appointment Confirmed - Staff' },
  { value: 16, text: 'Group Appointment Updated - Staff' },
  { value: 21, text: 'Advanced Waitlist Confirmed - Client' },
  { value: 22, text: 'Advanced Waitlist Availability - Client' },
  { value: 23, text: 'Advanced Waitlist Confirmed - Staff' },
  { value: 24, text: 'Appointment Cancelled by Staff - Staff' },
  { value: 25, text: 'Appointment Cancelled by Staff - Client' },
];
export const CustomEmailState = {
  PUBLISHED: 0,
  DRAFT: 1,
};
export const CustomEmailStateLabels = {
  [CustomEmailState.PUBLISHED]: 'CustomEmails.published',
  [CustomEmailState.DRAFT]: 'CustomEmails.draft',
};
export const defaultLanguage = 'en';
export const exchangeExpiryStartDays = 14;
export const OFFICE_365_DOMAIN = 'outlook.office365.com';
export const VERSION_2010 = 1;
export const VERSION_2010_SP1 = 2;
export const VERSION_2010_SP2 = 3;
export const VERSION_2013 = 4;
export const VERSION_2013_SP1 = 5;
export const VERSION_2016 = 6;
export const VERSION_OFFICE_365 = 7;
export const VERSION_2019 = 8;
export const exchangeVersions = [
  {
    label: '2010',
    value: VERSION_2010,
  },
  {
    label: '2010 SP1',
    value: VERSION_2010_SP1,
  },
  {
    label: '2010 SP2',
    value: VERSION_2010_SP2,
  },
  {
    label: '2013',
    value: VERSION_2013,
  },
  {
    label: '2013 SP1',
    value: VERSION_2013_SP1,
  },
  {
    label: '2016',
    value: VERSION_2016,
  },
  {
    label: '2019',
    value: VERSION_2019,
  },
  {
    label: 'Office 365',
    value: VERSION_OFFICE_365,
  },
];
export const exchangeAuthTypes = {
  BASIC_NTLM: 'basic',
  OAUTH: 'oauth',
};
export const eventTypes = {
  ABSENCE: 'App\\Models\\Absence',
  APPOINTMENT: 'App\\Models\\Appointment',
  ENGAGEMENT: 'App\\Models\\Engagement',
  WALK_IN: 'App\\Models\\QueueAppointment',
};
export const syncLogStatuses = {
  SUCCESS: 0,
  IN_PROGRESS: 1,
  SCHEDULED: 2,
  ERROR: 3,
  WARNING: 4,
};
export const syncLogActions = {
  CREATE: 0,
  UPDATE: 1,
  DELETE: 2,
};
export const syncLogDirections = {
  EXPORT: 0,
  IMPORT: 1,
};
export const userAccountsPermissionCategories = {
  SYNCING: 1,
  WARNING: 2,
  ERROR: 3,
};
export const userAccountsErrorCodes = {
  ACCESS_DENIED: 'ErrorAccessDenied',
  GRAPH_ACCESS_DENIED: 'AccessDenied',
  EXTENSION_ERROR: 'ExtensionError',
};
export const userAccountsWarningCodes = {
  NON_EXISTENT_MAILBOX: 'ErrorNonExistentMailbox',
  FOLDER_NOT_FOUND: 'ErrorFolderNotFound',
  ITEM_NOT_FOUND: 'ErrorItemNotFound',
  IMPERSONATION_DENIED: 'ErrorImpersonationDenied',
  IMPERSONATION_FAILED: 'ErrorImpersonationFailed',
  IMPERSONATE_USER_DENIED: 'ErrorImpersonateUserDenied',
  RESYNC_REQUIRED: 'ResyncRequired',
  ACTIVITY_LEVEL_REACHED: 'ResyncRequired',
};
export const insightsAggregationUnits = {
  DAY: 'day',
  MONTH: 'month',
  WEEK: 'week',
};
export const queueAppointmentStatuses = {
  CONFIRMED: 1,
  COMPLETE: 2,
  NO_SHOW: 3,
  CANCELLED: 4,
  IN_PROGRESS: 5,
};
export const INVITATION_STEPS = {
  CLIENT: 'client',
  SERVICE: 'service',
  LOCATION: 'location',
  TIMES: 'times',
  SUMMARY: 'summary',
};
export const resourceTypes = {
  ACCOUNT_SYNCING_STATUSES: 'account-syncing-statuses',
  ACTIVITY: 'activities',
  ADJUSTMENT: 'adjustments',
  ANSWER: 'answers',
  APPOINTMENT: 'appointments',
  ATTENDEE: 'attendees',
  BUSINESS_HOURS: 'business-hours',
  CANCELLATION_REASONS: 'cancellation-reasons',
  CATEGORY: 'categories',
  CLIENT: 'clients',
  COUNTRY: 'countries',
  DATATABLE_ADJUSTMENT: 'datatable-adjustments',
  DATA_EXPORT: 'data-export',
  DATA_EXPORT_TEMPLATE: 'data-export-template',
  ENGAGEMENT: 'engagement-resource',
  EXCHANGE_ACCOUNTS: 'exchange-accounts',
  GLIA: 'glia-integration',
  GROUP: 'groups',
  LANGUAGE: 'languages',
  LOCATION: 'locations',
  LOOKER_DASHBOARD: 'looker-dashboard',
  LOOKER_LOOK: 'looker-look',
  MEETING_METHOD: 'meeting-methods',
  LOOKER_EMBED_COPY_LOOK: 'looker-embed-copy-look',
  LOOKER_EMBED_COPY_DASHBOARD: 'looker-embed-copy-dashboard',
  LOOKER_EMBED_EXPLORES: 'looker-embed-explores',
  LOOKER_EMBED_FAVOURITE_CONTENT: 'looker-embed-favourite-content',
  LOOKER_EMBED_ORGANIZATION_CONTENT: 'looker-embed-organization-content',
  LOOKER_EMBED_USER_DASHBOARDS: 'looker-embed-user-dashboards',
  LOOKER_EMBED_USER_LOOKS: 'looker-embed-user-looks',
  LOOKER_EMBED_SHARED_DASHBOARDS: 'looker-embed-shared-looks',
  LOOKER_EMBED_SHARED_LOOKS: 'looker-embed-shared-looks',
  MICROSOFT_DYNAMICS_INTEGRATION: 'microsoft_dynamics_integration',
  POINT: 'point',
  QUESTION: 'questions',
  QUEUE_APPOINTMENT: 'queue-appointments',
  SCHEDULED_LOOKER_EXPORT: 'scheduled-looker-exports',
  SERVICE: 'services',
  SYNC_LOG_ACTIVITIES: 'sync-log-activities',
  SYNC_LOG_EVENT_DETAILS: 'sync-log-event-details',
  SYNC_LOGS: 'sync-logs',
  USER: 'users',
  ROLE: 'role',
  REGION: 'regions',
  ROOM: 'rooms',
  TAG: 'tags',
  TIMESLOTS: 'time-slots',
  TIMEZONE: 'timezones',
  VENDOR_EXCHNAGE_SETTINGS: 'vendor-exchange-settings',
  WORKFLOW: 'workflows',
  WORKING_HOURS: 'working-hours',
};
export const resourceState = {
  NULL: 0,
  STAFF: 1,
  LOCATION: 2,
  SERVICE: 3,
  CATEGORY: 4,
  ADDITIONAL_STAFF: 5,
};
export const SELECTION_COMMANDS = {
  CLIENT: 1,
  SERVICE: 2,
  LOCATION: 3,
  TIMES: 4,
  CLEAR_TIMES: 5,
  TIMEZONE: 6,
  CLEAR_ALL: 7,
};
export const states = {
  LIVE: 'live',
  PAID: 'paid',
};
export const TEMPLATE_ID = 'email-template';
export const staffDashboardVersions = {
  ONE: 1,
  TWO: 2,
};
export const RESOURCE = {
  CALL_CENTRE: 'contact_centre',
  HOURS: 'hours',
  LOBBY: 'lobby_view',
  SHORTCUTS: 'shortcuts',
  SCHEDULE: 'schedule',
};

export const SORTING = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

export const CLIENT_VIEW_LAYOUTS = {
  CLASSIC: 'old',
  MODERN: 'new',
};

export const INTERVALS = {
  ONE_MINUTE: 60000,
  ONE_SECOND: 1000,
};

export const MEETING_METHODS = {
  AT_BUSINESS: 1,
  PHONE_CALL: 2,
  VIDEO_CALL: 3,
  OFF_SITE: 4,
};

export const MEETING_METHODS_NAMES = {
  [MEETING_METHODS.AT_BUSINESS]: 'MeetingMethods.in_person',
  [MEETING_METHODS.PHONE_CALL]: 'MeetingMethods.phone',
  [MEETING_METHODS.VIDEO_CALL]: 'MeetingMethods.video',
  [MEETING_METHODS.OFF_SITE]: 'MeetingMethods.off_site',
};

export const MEETING_METHOD_LABELS = {
  [MEETING_METHODS.AT_BUSINESS]: 'MeetingMethods.in_person_appointment',
  [MEETING_METHODS.PHONE_CALL]: 'MeetingMethods.phone_appointment',
  [MEETING_METHODS.VIDEO_CALL]: 'MeetingMethods.video_appointment',
  [MEETING_METHODS.OFF_SITE]: 'MeetingMethods.off_site_appointment',
};

export const MEETING_METHOD_LIST = [
  {
    id: String(MEETING_METHODS.AT_BUSINESS),
    attributes: {
      name: 'Shortcuts.meeting_method.at_location',
      description: 'MeetingMethods.business_description',
    },
  },
  {
    id: String(MEETING_METHODS.OFF_SITE),
    attributes: {
      name: 'Shortcuts.meeting_method.client_location',
      description: 'MeetingMethods.off_site_description',
    },
  },
  {
    id: String(MEETING_METHODS.PHONE_CALL),
    attributes: {
      name: 'Shortcuts.meeting_method.phone_call',
      description: 'MeetingMethods.phone_call_description',
    },
  },
  {
    id: String(MEETING_METHODS.VIDEO_CALL),
    attributes: {
      name: 'Shortcuts.meeting_method.video_call',
      description: 'MeetingMethods.video_call_description',
    },
  },
];

export const FULFILLMENT_METHODS = {
  WALK_IN: 1,
  CURBSIDE: 2,
};

export const FULFILLMENT_METHODS_NAMES = {
  [FULFILLMENT_METHODS.WALK_IN]: 'FulfillmentMethods.walk_in',
  [FULFILLMENT_METHODS.CURBSIDE]: 'FulfillmentMethods.curbside',
};

export const TOGGLE = {
  ON: 'on',
  OFF: 'off',
};

export const NOTIFICATION_EVENT_STATUSES = {
  ERROR: 1,
  SENDING: 2,
  FAILED: 3,
  SUCCESS: 4,
};

export const NOTIFICATION_OPTIONS = {
  REQUIRED: 'required',
  OPTIONAL: 'optional',
  HIDDEN: 'na',
};

export const WAIT_TIME = {
  SERVICE_LENGTH: 0,
  AVERAGE_HANDLE_TIME: 1,
};

export const VIRTUAL_MEETING_METHODS = [
  MEETING_METHODS.PHONE_CALL,
  MEETING_METHODS.VIDEO_CALL,
];

export const BUSINESS_HOURS_LINK =
  'https://coconutcalendar.zendesk.com/hc/en-us/articles/360031128632-How-to-manage-Business-Hours';
export const WORKING_HOURS_LINK =
  'https://coconutcalendar.zendesk.com/hc/en-us/articles/360031487571-How-to-manage-Working-Hours';
export const ADJUSTMENTS_LINK =
  'https://coconutcalendar.zendesk.com/hc/en-us/articles/360031135692-How-to-adjust-hours';

export const SUPPORT_PHONE = '+1(888) 257-1309 x 2';
export const SUPPORT_PHONE_W_EXT = '+1(888)257-1309;ext=2';
export const EMERGENCY_EMAIL = 'emergency@coconutsoftware.com';
export const EMERGENCY_TELEPHONE = '+1(855) 980-5809';

export const ZENDESK_URL = 'https://coconutcalendar.zendesk.com/hc/en-us';
export const ZENDESK_UPDATES = `${ZENDESK_URL}/categories/360001732992-Coconut-Updates`;
export const ZENDESK_NEW_REQUEST = `${ZENDESK_URL}/requests/new`;

export const DEFAULT_COUNTRY = 'US';
export const POPULAR_COUNTRIES = ['CA', 'US'];

export const VIDEO_PLATFORMS = {
  MS_TEAMS: 1,
  ZOOM: 2,
  CONNECT: 3,
  OTHER: 4,
};

export const VIDEO_PLATFORM_NAMES = {
  [VIDEO_PLATFORMS.MS_TEAMS]: 'teams',
  [VIDEO_PLATFORMS.ZOOM]: 'zoom',
  [VIDEO_PLATFORMS.CONNECT]: 'connect',
  [VIDEO_PLATFORMS.OTHER]: 'other',
};

export const VIDEO_INTEGRATION_JOIN_LABELS = {
  [VIDEO_PLATFORMS.CONNECT]: 'VideoPlatforms.join_connect',
  [VIDEO_PLATFORMS.MS_TEAMS]: 'VideoPlatforms.join_teams',
  [VIDEO_PLATFORMS.ZOOM]: 'VideoPlatforms.join_zoom',
  [VIDEO_PLATFORMS.OTHER]: 'VideoPlatforms.join_call',
  [null]: 'VideoPlatforms.join_call',
};

export const MS_GRAPH_INTEGRATIONS = {
  VIDEO: 'video',
  MICROSOFT_ENTRA_ID: 'active-directory',
};

export const ORIGINS = {
  COCONUT: 0,
  MICROSOFT_ENTRA_ID: 1,
};

export const ORIGIN_LABELS = {
  [ORIGINS.COCONUT]: 'Label.coconut',
  [ORIGINS.MICROSOFT_ENTRA_ID]: 'Label.microsoft_entra_id',
};

export const uncategorizedId = 'uncategorized';

export const CallToActionTypes = {
  PHONE: '0',
  EMAIL: '1',
  WEBSITE: '2',
};

export const SHORTCUTS = {
  ADDITIONAL_STAFF: 'additional_staff',
};

export const NotificationChannels = {
  SMS: '1',
  EMAIL: '2',
};

export const REMINDER_TYPES = {
  SMS: 'sms',
  EMAIL: 'email',
  BOTH: 'both',
  COMPLETED_EMAIL: 'email_completed',
  EMAIL_STAFF_UPCOMING: 'email_staff_upcoming',
};

export const NOTIFICATION_RECIPIENT_TYPES = {
  USER: 'user',
  CLIENT: 'client',
};

export const DenylistedRecipientChannels = {
  EMAIL_CHANNEL: 1,
  SMS_CHANNEL: 2,
};

export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const dateFormat = 'YYYY-MM-DD';
export const time24HFormat = 'HH:mm:ss';
export const time12HFormat = 'h:mm A';

export const TEXT_FIELD_TYPE = {
  email: 'email',
  url: 'url',
  text: 'text',
};

export const UnitsOfTime = {
  DAY: 'days',
  HOUR: 'hours',
  WEEK: 'weeks',
  MINUTE: 'minutes',
};

export const URL_EVENT_APPOINTMENT_TYPE = 'appt';
export const URL_EVENT_QUEUE_APPOINTMENT_TYPE = 'queue';

export const URL_EVENT_TYPES = [
  URL_EVENT_APPOINTMENT_TYPE,
  URL_EVENT_QUEUE_APPOINTMENT_TYPE,
];

export const SCHEDULE_THEMES = {
  LIGHT_TEXT: 1,
  DARK_TEXT: 2,
};

export const APPOINTMENT_TYPES = {
  CLIENT: 0,
  GROUP: 2,
};

export const FEEDBACK_TYPES = {
  COCONUT: 0,
  CUSTOM: 1,
  GOOGLE: 2,
};

export const API_ERROR_CODES = {
  FAILED_TO_GENERATE_MEETING_LINK: 1000,
  CANNOT_HAVE_START_DATE_IN_THE_PAST: 1001,
  CANNOT_RESCHEDULE_MEETING_LINK: 1002,
  CANNOT_DELETE_MEETING_LINK: 1003,
};

export const LOOKER_EMBED_MANAGE_GROUP = 2;
export const LOOKER_EMBED_VIEW_GROUP = 3;

export const engagementStatuses = {
  CONFIRMED: 1,
  COMPLETE: 2,
  NO_SHOW: 3,
  CANCELLED: 4,
  IN_PROGRESS: 5,
};

export const DOCUMENT_PROVIDERS = {
  DOCUSIGN: 1,
  ONESPAN: 2,
};

export const SCHEDULE_TYPE = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
};

export const RELEASE_STATUS = {
  DEV: 'dev',
  ALPHA: 'alpha',
  BETA: 'beta',
  GENERAL: 'general',
};

export const ADD_NEW_FROM_ANYWHERE = 'add_new_from_anywhere';

export const MODE = {
  COCONUT: 'coconut',
  DYNAMICS: 'dynamics',
};

export const INTEGRATION_MODES = [MODE.DYNAMICS];

export const INTEGRATION_POST_MESSAGES = {
  APPOINTMENT_BOOKED: 'appointment_booked',
  APPOINTMENT_CANCELLED: 'appointment_cancelled',
  APPOINTMENT_UPDATED: 'appointment_updated',
};

export const PHONE_TYPES = {
  phone: 'phone',
  cell_phone: 'cell_phone',
  work_phone: 'work_phone',
};

export const TOLL_FREE_VERIFICATION_STATUSES = {
  PENDING: 1,
  IN_REVIEW: 2,
  APPROVED: 3,
  REJECTED: 4,
};

export const TAG_MANAGER_GOOGLE = 'google_tag_manager';
export const TAG_MANAGER_TEALIUM = 'tealium_tag_manager';
export const TAG_MANAGER_ADOBE = 'adobe_launch';

export const TAG_MANAGERS = {
  [TAG_MANAGER_GOOGLE]: 1,
  [TAG_MANAGER_TEALIUM]: 2,
  [TAG_MANAGER_ADOBE]: 3,
};

export const DEFAULT_PRIMARY_PHONE_TYPE = 'cell_phone';

export const ADD_NEW_USE_CONTACT_CENTRE = 'contact_centre';

export const ADD_NEW_USE_SCHEDULE = 'schedule';

export const OLD_JS_PATHS = [
  '/services',
  '/staff/settings',
  '/settings/api',
  '/settings/questions',
  '/settings/payments',
  '/vendors',
];

export const CHAT_TYPE = {
  ADVISOR: 1,
  LIVE_AGENT: 2,
};
export const ENGAGEMENTS_PAGINATION_LIMIT = 20;

export const CRM = {
  SALESFORCE: 'salesforce',
};

export const LOOKER_EXPORT_STATUS_INTL_IDS = {
  0: 'AdvancedAnalytics.sftp.scheduled',
  1: 'AdvancedAnalytics.sftp.in_progress',
  2: 'AdvancedAnalytics.sftp.completed',
  3: 'AdvancedAnalytics.sftp.failed',
  4: 'AdvancedAnalytics.sftp.cancelled',
  5: 'AdvancedAnalytics.sftp.no_looker_user',
  6: 'AdvancedAnalytics.sftp.user_archived',
};

export const LAYOUT_SYNC_STATUS = {
  NOT_STARTED: 0,
  STARTED: 1,
  ERROR: 2,
  PARTIAL_ERROR: 3,
  SUCCESS: 4,
};

export const VIDEO_ACCESS_CODE_LENGTH = 9;

export const remoteAvailabilityStatus = {
  ON: 1,
  OFF: 0,
};
